export default {
  ALL: [
    //0:
    {
      NAME: "Basic",
      TYPE: 2, // 0 play, 1 package, 2 apk
      BILLED: 0, // 0 monthly, 1 6m, 2 yearly, 3 2 years -> billing cycles
      DURATION: 1,
      USERS: 1,
      PRICE: "19.99",
      PRICE_M: "19.99",
      REF: "",
    },
    //1:
    {
      NAME: "Basic",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 1, // 0 monthly, 1 6m, 2 yearly
      DURATION: 6,
      USERS: 1,
      PRICE: "107.99",
      PRICE_M: "17.99",
    },
    //2:
    {
      NAME: "Basic",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 2, // 0 monthly, 1 6m, 2 yearly
      DURATION: 12,
      USERS: 1,
      PRICE: "179.99",
      PRICE_M: "14.99",
    },

    //3:
    {
      NAME: "Duo",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 0, // 0 monthly, 1 6m, 2 yearly
      DURATION: 1,
      USERS: 2,
      PRICE: "29.99",
      PRICE_M: "29.99",
    },
    //4:
    {
      NAME: "Duo",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 1, // 0 monthly, 1 6m, 2 yearly
      DURATION: 6,
      USERS: 2,
      PRICE: "159.99",
      PRICE_M: "26.65",
    },
    //5:
    {
      NAME: "Duo",
      SKU: "2_ci_subscription.yearly",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 2, // 0 monthly, 1 6m, 2 yearly
      DURATION: 12,
      USERS: 2,
      PRICE: "269.99",
      PRICE_M: "22.49",
    },

    //6:
    {
      NAME: "Family",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 0, // 0: monthly, 1: 6m, 2: yearly, 3: 3y
      DURATION: 1,
      USERS: 4,
      PRICE: "49.99",
      PRICE_M: "49.99",
    },

    //7:
    {
      NAME: "Package",
      TYPE: 1, // 1 package, 2 apk
      BILLED: 2, // 0: monthly, 1: 6m, 2: yearly, 3: 3y
      DURATION: 12,
      USERS: 1,
      PRICE: "1299",
      PRICE_M: "",
    },

    //8:
    {
      NAME: "Package",
      TYPE: 1, // 1 package, 2 apk
      BILLED: 3, // 0: monthly, 1: 6m, 2: yearly, 3: 3y
      DURATION: 36,
      USERS: 1,
      PRICE: "1799",
      PRICE_M: "",
    },

    //9:
    {
      NAME: "Family",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 1, // 0: monthly, 1: 6m, 2: yearly, 3: 3y
      DURATION: 1,
      USERS: 4,
      PRICE: "299.99",
      PRICE_M: "299.99",
    },

    //10:
    {
      NAME: "Family",
      TYPE: 2, // 1 package, 2 apk
      BILLED: 2, // 0: monthly, 1: 6m, 2: yearly, 3: 3y
      DURATION: 1,
      USERS: 4,
      PRICE: "499.99",
      PRICE_M: "499.99",
    },
  ],

  BILLED_INDEX: ["monthly", "every 6 months", "yearly", "every 3 years"],

  STATUS_INDEX: [
    "unverified", //0
    "active", // 1
    "grace period", // 2
    "on hold", // 3
    "cancelled", // 4
    "inactive", // 5
    "expired", // 6
    "payment failed", // 7
    "payment pending", //8
    "suspended", // 9
    "approved", //10
    "approval pending", //11
    "in delivery", // 12
  ],
  STATUS: {
    UNVERIFIED: 0,
    ACTIVE: 1,
    GRACE_PERIOD: 2,
    ON_HOLD: 3,
    CANCELLED: 4,
    INACTIVE: 5,
    EXPIRED: 6,
    PAYMENT_FAILED: 7,
    PAYMENT_PENDING: 8,
    SUSPENDED: 9,
    APPROVED: 10,
    APPROVAL_PENDING: 11,
    IN_DELIVERY: 12,
  },
  
  TYPE_INDEX: ["Google Play", "Package", "App"],
  TYPE: {
    GOOGLE_PLAY: 0,
    PACKAGE: 1,
    APP: 2,
  },
  DEFAULT_SUB: {
    id: 14,
    userId: "fIUYdm4VF5fUnyFKJ9YXjLzrrLN2",
    aacType: 2,
    panId: 2,
    planName: "Basic",
    start: "2021-01-20 18:03:57",
    expiry: "2022-01-20 18:03:57",
    duration: "12",
    price: 179.99,
    orderId: "90G29639J45874040",
    isPaid: true,
    deliveryID: "",
    isDelivered: false,
    status: 1,
    reg_date: "2021-01-20 19:04:15",
  },

  DEFAULT_SUBSCRIPTION: {
    id: 14,
    userId: "fIUYdm4VF5fUnyFKJ9YXjLzrrLN2",
    aacType: 2,
    panId: 2,
    planName: "Basic",
    start: "2021-01-20 18:03:57",
    expiry: "2022-01-20 18:03:57",
    duration: "12",
    price: 179.99,
    orderId: "90G29639J45874040",
    isPaid: true,
    deliveryID: "",
    isDelivered: false,
    status: 1,
    reg_date: "2021-01-20 19:04:15",
  },

  PRODUCT_INDEX: ["basic", "duo", "family", "package 1", "package 2"],
  PRODUCT: {
    BASIC: 0,
    DUO: 1,
    FAMILY: 2,
    PACKAGE_ONE: 3,
    PACKAGE_TWO: 4,
  },

  // loadPlan: function(planId) {
  //   var plan = {
  //     title: PLANS.ALL[0].NAME,
  //     billed: PLANS.BILLED_INDEX[PLANS.ALL[0].BILLED],
  //     PLAN: PLANS.ALL[0],
  //     isPackage: false,
  //   };
  //   return plan;
  // },
};
