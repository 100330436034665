<template>
  <div>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="step-account"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <!-- <div class="steps-container steps-container-3">
          <a href="#" class="steps-nav-item">Step 1</a>
          <a href="#" class="steps-nav-item">Step 2</a>
          <a href="#" class="steps-nav-item active">Step 3</a>
        </div> -->
                <div class="steps-container">
          <router-link :to="{ name: 'Plans', query: { t: isPackage ? 1 : 0 } }"
            ><i class="fa fa-arrow-left"></i> Back to plans</router-link
          >
        </div>
        <div class="section-detail">
          <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->
          <!-- <div class="mb-3 jcard jcard-padding-30 jcard-nohover">
          <p>Talkii {{ planTitle }}</p>
          <p>{{ PLAN.USERS }} user(s)</p>
          <p></p>
          <p>
            Total <strong>{{ PLAN.PRICE }}€</strong>
          </p>
        </div> -->
          <div
            id="plan-detail"
            class="jcard jcard-detail price-platform price-sm"
          >
            <h5>TALKII {{ planTitle }}</h5>
            <div>
              <h3 class="mb-0">{{ PLAN.PRICE }}€</h3>
              <p>
                <i>billed {{ billedText }}</i>
              </p>
            </div>
            <p>
              <small
                >You’ll be charged €{{ PLAN.PRICE }} {{ billedText }} until you
                cancel your subscription.</small
              >
            </p>
            <div class="jcard-separator"></div>
            <div class="jcard-items-list">
              <p><i class="fal fa-user"></i></p>
              <p>{{ PLAN.USERS }} user(s)</p>
              <p><i class="fal fa-mobile-android"></i></p>
              <p>Supervisor App</p>
              <p><i class="fal fa-tablet-android"></i></p>
              <p>Tablet App</p>
            </div>

            <router-link class="jbtn jbtn-blue" :to="{ name: 'Password' }">
              Confirm
            </router-link>
          </div>

          <div class="jcard jcard-main jcard-register jcard-nohover">
            <div class="mb-3" v-if="displayBilling">
              <div class="sub-title mb-4">
                <h3>Billing cycle</h3>
              </div>
              <div class="cycles-container">
                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>Monthly</b></p>
                    <p>19.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>6 months</b></p>
                    <p>17.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>Yearly</b></p>
                    <p>15.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>3 years</b></p>
                    <p>13.99€ / month</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-title mb-4">
              <h3>Payment</h3>
            </div>

            <div class="payment-options mt-4">
              <div
                @click="changeOption(0)"
                class="inner-jcard inner-jcard-70 inner-jcard-hover mb-3"
              >
                <h5>
                  <b>Credit Card</b>
                  <span class="fa fa-cc-visa text-light-blue ml-2"></span>&nbsp;
                  <span class="fa fa-cc-mastercard text-light-blue"></span>
                </h5>
                <!-- Element to collapse -->
                <div v-if="paymentOptions[0]" class="mt-1">
                  <p>
                    <small
                      ><i class="fa fa-info-circle text-light-blue"></i> You
                      will be redirected to PayPal, where you can select the
                      Credit Cards option.</small
                    >
                  </p>
                </div>
              </div>

              <div
                @click="changeOption(1)"
                class="inner-jcard inner-jcard-70 inner-jcard-hover mb-3"
              >
                <h5>
                  <b>PayPal</b><span class="fa fa-cc-paypal text-light-blue ml-2"></span>
                </h5>
                <div v-if="paymentOptions[1]" class="mt-1">
                  <p>
                    <small
                      ><i class="fa fa-info-circle text-light-blue"></i> Confirm
                      your order to be redirected to Paypal.</small
                    >
                  </p>
                </div>
              </div>
            </div>

            <!-- <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="email">
                      <i class="fad fa-user"></i> Card Number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.firstName"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="email"><i class="fad fa-user"></i> NNN</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.lastName"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="email"
                  ><i class="fad fa-map-marker"></i> Expiry date</label
                >
                <input
                  type="date"
                  class="form-control"
                  v-model="userData.address"
                />
              </div> -->

            <!-- <div ref="paypal"></div> -->

            <!-- <div class="btn-container text-center mt-4 mb-4">
                <button
                  class="jbtn jbtn-blue"
                  value="Add"
                  @click="registerUser"
                >
                  Singup!
                </button>
                <button
                  class="jbtn jbtn-blue"
                  value="Add"
                  @click="addSubscription"
                >
                  Add subscription
                </button>
              </div> -->

            <div class="btn-container text-center mt-4 mb-4">
              <button
                class="jbtn jbtn-blue mr-2"
                value="Add"
                @click="createPayPalAgreement()"
              >
                Subscribe
              </button>

              <!-- <button
                class="jbtn jbtn-blue"
                value="Add"
                @click="payPalWebhook()"
              >
                Webhook
              </button> -->
            </div>

            <div class="login-message">
              <p>{{ validationMsg }}</p>
              <p>{{ pwNotOkMsg }}</p>
            </div>

            <div class="bottom-links text-center">
              <p>
                <small
                  >By confirming your order and purchasing a subscription you
                  accept our
                  <a href="https://talkii.app/terms" target="_blank"
                    >Terms of Service</a
                  >.</small
                >
              </p>
            </div>
            <hr>
            <div class="bottom-links text-center">
              <p>
                <!-- <small> -->
                <b><i class="fa fa-life-ring"></i> Need any help?</b>
                You can <a href="#">contact us</a>.
                <!-- </small> -->
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
// import db from "../../firebase/firebaseInit";
import axios from "axios";
// import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "CreateAccount",
  data() {
    return {
      //global
      planTitle: PLANS.ALL[0].NAME, //planTitle
      billedText: PLANS.BILLED_INDEX[PLANS.ALL[0].BILLED],
      planId: 0,
      PLAN: PLANS.ALL[0],
      isPackage: false,
      displayBilling: false,

      paymentOptions: [false, false],
      paymentOption: -1,

      //form input data
      userData: {
        firstName: "",
        lastName: "",
        address: "",
        zip: "",
        country: "Luxembourg",
        phone: "",
        email: "",
        password: "",
        passwordCheck: "",
      },

      //subscription
      subscription: {
        userId: null, //firebase.auth().currentUser.uid //from firebase
        aacType: 0, //subscription type, from plan
        planId: 0, //sku, from plan
        planName: "", //subscription name, from plan
        start: Date.now, //from paypal
        expiry: Date.now, //from paypal
        duration: 0, //from plan
        price: 0, //from plan
        orderId: "NULL", //from paypal
        isPaid: false, //from paypal
        deliveryId: "NULL",
        isDelivered: false,
        status: PLANS.STATUS.FAILED, //from paypal
        //description: "",
      },

      validationMsg: "",
      pwNotOkMsg: "",

      //paypal (old)
      loaded: false,
    };
  },
  computed: {
    buy: {
      get() {
        return this.$store.getters.getBuy;
      },
    },
  },
  beforeMount() {
    //this.init();

    // check/load store -> back to selection
    // no selected plan
    if (this.buy == null || this.buy.plan == null) {
      alert("No plan selected. Return to plan selection.");
      console.log("No plan selected in state. Return to plan selection.");

      this.$router.push({
        name: "Plans",
      });
    } else {
      // selected plan
      console.log("{payment} -> store plan: " + this.buy.plan);
      //init global data from plan
      this.planId = this.buy.plan;
      this.planTitle = PLANS.ALL[this.buy.plan].NAME;
      this.billedText = PLANS.BILLED_INDEX[PLANS.ALL[this.buy.plan].BILLED];
      this.PLAN = PLANS.ALL[this.buy.plan];
      if (this.PLAN.TYPE == 1) {
        this.isPackage = true;
        console.log("buy: is package");
      }
      //init subscription data from plan
      this.subscription.aacType = this.PLAN.TYPE;
      this.subscription.planName = this.PLAN.NAME;
      this.subscription.planId = this.planId;
      this.subscription.duration = this.PLAN.DURATION;
      this.subscription.price = this.PLAN.PRICE;
      console.log("Subscription price: " + this.subscription.price);
    }
    console.log("plan: " + this.PLAN);
  },
  methods: {
    //called when PayPal sdk is loaded
    setLoaded: function() {
      this.loaded = true;
    },
    changeOption(clicked) {
      if (this.paymentOption == -1) {
        this.$set(this.paymentOptions, clicked, true);
        this.paymentOption = clicked;
      } else if (this.paymentOption == clicked) {
        this.$set(this.paymentOptions, clicked, false);
        this.paymentOption = -1;
      } else {
        this.$set(this.paymentOptions, this.paymentOption, false);
        this.$set(this.paymentOptions, clicked, true);
        this.paymentOption = clicked;
      }
    },
    createPayPalAgreement() {
      axios
        .post("/api/payment.php", {
          request: "create_subscription",
          planId: "P-2U726194MY693043VMAKJXZQ",
          userId: firebase.auth().currentUser.uid,
        })
        .then(function(response) {
          //alert(response.data);
          console.log(response.data);
          window.location.href = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    payPalWebhook() {
      axios
        .post("/api/webhook.php", {
          request: 2,
          resource: { id: "I-FR7NDYE9DHT2", status: "TESTING" },
        })
        .then(function(response) {
          //alert(response.data);
          console.log(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    addSubscription() {
      //   if (
      //     this.userId != "" &&
      //     this.aacType != "" &&
      //     this.expiry != "" &&
      //     this.orderId != ""
      //   ) {
      axios
        .post("/api/functions.php", {
          request: 2,
          userId: this.subscription.userId,
          aacType: this.subscription.aacType,
          planName: this.subscription.planName,
          planId: this.subscription.planId,
          start: this.subscription.start,
          expiry: this.subscription.expiry,
          duration: this.subscription.duration,
          price: this.subscription.price,
          orderId: this.subscription.orderId,
          isPaid: this.subscription.isPaid,
          deliveryId: this.subscription.deliveryId,
          isDelivered: this.subscription.isDelivered,
          status: this.subscription.status,
        })
        .then(function(response) {
          // Fetch records
          //app.allRecords();

          // Empty values
          //app.orderId = "";
          //alert(response.data);
          console.log(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
      //   } else {
      //     alert("Fill all fields.");
      //   }
    },
    getPlan() {
      axios
        .post("/api/functions.php", {
          request: 1,
        })
        .then(function(response) {
          this.PLAN = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    validate() {
      this.pwNotOkMsg = "";
      this.validPassword = true;
      return true;
    },
    checkEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/plans.scss";
</style>
